import instagram from '../images/insta.png';
import facebook from '../images/fb.png';
import mail from '../images/mail.png';
import catalog from '../images/catalog.webp';
import logo from '../images/logo.png';

export const App = () => {
    return (
        <div className="app">
            <div className="background" />
            <div className="content">
                <div className="socials">
                    <a href="https://www.instagram.com/oela_graphic_design" target="_blank" rel="noreferrer" className="link socials__link">
                        <img src={instagram} alt="instagram icon" className="img" />
                    </a>
                    <a href="https://www.facebook.com/Oela-Graphic-Design-104706962231724" target="_blank" rel="noreferrer" className="link socials__link">
                        <img src={facebook} alt="facebook icon" className="img" />
                    </a>
                    <a href="mailto:info@oela.pl" target="_blank" rel="noreferrer" className="link socials__link">
                        <img src={mail} alt="mail icon" className="img" />
                    </a>
                </div>
                <div className="logo">
                    <img src={logo} alt="Logo OELA Graphic Design" className="img img--natural" />
                </div>
                <h1 className="title">Strona w przygotowaniu</h1>
                <div className="catalog">
                    <h2 className="subtitle">Zapraszamy do katalogu:</h2>
                    <a href="https://heyzine.com/flip-book/ad228bb913#page/1" target="_blank" rel="noreferrer" className="catalog__link">
                        <img src={catalog} alt="catalog icon" className="img" />
                    </a>
                </div>
            </div>
        </div>
    );
};